import { Flex, Text, Button, Image, Input, Box } from "theme-ui";
import { Header } from "../Components";
import { Device, FormComponents, WizardProps, extractFormComponentDefaults } from "../models";
import * as Loader from "react-loader-spinner";
import { useEffect, useState } from "react";
import { ApiClient, HTTPError } from "../lib/api";
import { StepWizardChildProps } from "react-step-wizard";
import { RiLockLine } from "react-icons/ri";
import { DeviceForm } from "./InfoPage";
import { link_error_consumer_facing_copy } from "../lib/link_errors";

interface PasswordPageProps extends StepWizardChildProps {
  device: Device;
  state: string;
  onClose: () => void;
  isMobile: boolean;
  token: string;
  env: string;
  region: string;
  showBackButton: boolean;
  setWizardProps: (data: WizardProps) => void;
}

const LogoHeader = ({ logo, name, description }) => (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      mb: "10px",
      mt: 20,
      px: 4,
    }}
  >
    <Image src={logo} width={60} sx={{ mr: 10 }} />
    <Flex sx={{ flexDirection: "column" }}>
      <Text sx={{ fontSize: 2, fontWeight: 600 }}>{name}</Text>
      <Text sx={{ fontSize: 0 }}>{description}</Text>
    </Flex>
  </Flex>
);

const CredentialsInput = ({
  onChange,
  currentForm,
}: {
  onChange: (currentFrom: UsernamePasswordForm) => void;
  currentForm: UsernamePasswordForm;
}) => {
  return (
    <Box px={2} py={4} sx={{ width: "100%" }}>
      <Text sx={{ fontWeight: 800, fontSize: 3 }}>Enter your credentials</Text>
      <div style={{ position: "relative" }}>
        <Input
          placeholder={"Email"}
          mb={3}
          mt={4}
          name="email"
          type="email"
          onChange={(e) =>
            onChange({ ...currentForm, username: e.target.value })
          }
        />
        <RiLockLine
          size={20}
          style={{ position: "absolute", right: 15, top: 15 }}
        />
      </div>
      <div style={{ position: "relative", marginBottom: "15px" }}>
        <Input
          placeholder={"Password"}
          name="password"
          type="password"
          onChange={(e) =>
            onChange({ ...currentForm, password: e.target.value })
          }
        />
        <RiLockLine
          size={20}
          style={{ position: "absolute", right: 15, top: 15 }}
        />
      </div>
    </Box>
  );
};

interface UsernamePasswordForm {
  username: string;
  password: string;
  [key: string]: string;
}

export const PasswordPage: React.FunctionComponent<
  Partial<PasswordPageProps>
> = ({
  device,
  state,
  token,
  onClose,
  env,
  showBackButton,
  region,
  setWizardProps,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [currentForm, setForm] = useState<UsernamePasswordForm>({
    username: "",
    password: "",
  });

  const [error, setError] = useState<{ error_type: string, status?: number } | null>(null);
  const client = new ApiClient();

  useEffect(() => {
    setForm(() => {
      // Reset the form value when the device slug is first set or is changed.
      return {
        username: "",
        password: "",
        ...extractFormComponentDefaults(device?.form_components),
      };
    });

  }, [device?.slug, device?.form_components, setForm]);

  const handleSubmit = async (currentForm: UsernamePasswordForm) => {
    console.log("currentForm", currentForm)
    if (!(currentForm.username && currentForm.password)) return null;
    setLoading(true);
    setError(null);
    try {
      const resp = await client.passwordAuth(
        currentForm.username,
        currentForm.password,
        device.slug,
        token,
        env,
        currentForm.region ?? null,
        region
      );

      switch (resp.state) {
        case "success":
          setWizardProps({ linkCompleted: { redirectUrl: resp.redirect_url } });
          props.goToNamedStep("state");
          break;

        case "error":
          setLoading(false);
          setError({ error_type: resp.error_type });
          break;

        case "pending_provider_mfa":
          setWizardProps({ provider_mfa: resp.provider_mfa });
          props.goToNamedStep("provider_mfa");
          break;
      }

    } catch (e) {
      setLoading(false);
      setError({ error_type: "unknown", status: (e instanceof HTTPError ? e.status : null) });
    }
  };

  if (!device) {
    return null;
  }

  const shouldRenderForm =
    device?.form_components &&
    device.form_components?.inputs?.length > 0 &&
    device.form_components?.instructions?.length > 0;

  return (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: "100%",
        width: "100%",
      }}
      pt={4}
    >
      <Flex
        sx={{
          alignItems: "flex-start",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Header
          showBack={!loading && showBackButton}
          onBack={() => props.previousStep()}
          onClose={onClose}
          hideExitButton={false}
        />
        <LogoHeader
          name={device.name}
          logo={device.logo}
          description={device.description}
        />
        <Box px={4} style={{ width: "100%" }}>
          {shouldRenderForm ? (
            <DeviceForm
              onChange={setForm}
              currentForm={currentForm}
              device={device}
            />
          ) : (
            <CredentialsInput onChange={setForm} currentForm={currentForm} />
          )}
          {error && <ErrorText error_type={error.error_type} />}
        </Box>
      </Flex>

      <Flex px={4} sx={{ width: "100%" }} pb={4}>
        <Button
          onClick={() => handleSubmit(currentForm)}
          sx={{ width: "100%", mt: 10 }}
        >
          <Box sx={{ height: 25 }}>
            {loading ? (
              <Loader.Oval color={"white"} width={25} height={25} wrapperStyle={{"justifyContent": "center"}}/>
            ) : (
              "Submit"
            )}
          </Box>
        </Button>
      </Flex>
    </Flex>
  );
};

const ErrorText: React.FC<{ error_type: string, status?: number }> = (error) => {
  return (
    <Text sx={{ color: "red", fontSize: 1, textAlign: "center" }}>
      {link_error_consumer_facing_copy(error.error_type, error.status)}
    </Text>
  );
};
