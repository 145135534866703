import { useState, useEffect } from "react";
import { getBaseUrl } from "../lib/Config";
import { fetcher, checkTokenConfig } from "../lib/api";
import useSWR from "swr";
import { useHistory } from "react-router";
import { parse } from "query-string";
import { Device, VITAL_EVENTS_V1 } from "../models";
import { sendEventMessage } from "../lib/utils";

const fetchData = (url: string, token: string) =>
  fetch(url, { headers: { LinkToken: token } });

export const GetLinkParameters = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isInvalid, setIsInvalid] = useState<boolean | null>(null);
  const [providers, setProviders] = useState<Device[]>([]);
  const [teamConfig, setTeamConfig] = useState<{ name: string; logo: string; }>(null);
  const [UIState, setUIState] = useState<"open" | "closed">("closed");
  const [finishedConnecting, setFinishedConnecting] = useState<boolean>(false);
  const [initialPage, setInitialPage] = useState({
    id: "consent",
    provider: null,
  });

  const {
    token,
    isMobile: mobileQuery,
    env,
    origin,
    region,
  }: {
    token?: string;
    isMobile?: string;
    origin?: string;
    env?: string;
    region?: string;
  } = parse(history.location.search);
  const isMobile = mobileQuery === "true" || mobileQuery === "True";

  let BASE_URL;
  if (region == null || region === "null" || region === "undefined") {
    BASE_URL = getBaseUrl(env);
  } else {
    BASE_URL = getBaseUrl(env, region);
  }

  const setInvalidParams = () => {
    setIsInvalid(true);
    setIsLoading(false);
    setProviders([]);
  };

  const handleSingleIntegration = (config) => {
    if (config.auth_type === "oauth" || config.auth_type === "team_oauth") {
      // If single integration flow check if oauth and redirect
      window.open(config.url, "_self");
    } else {
      // Skip to device page
      setInitialPage({ id: "password", provider: config.provider });
      setUIState("open");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleToken = async () => {
      const [isInvalid, config] = await checkTokenConfig(token, env, region);
      if (!isInvalid && config.flow === "single_integration") {
        await handleSingleIntegration(config);
      } else {
        let [resp, resp2] = await Promise.all([
          fetchData(`${BASE_URL}/link/providers`, token),
          fetchData(`${BASE_URL}/team/link/config/`, token),
        ]);
        if (!resp.ok || !resp2.ok) {
          setUIState("open");
          setInvalidParams();
        } else {
          try{
            const provs = await resp.json();
            const config = await resp2.json();
            setTeamConfig(config);
            setProviders(provs);
            setIsInvalid(isInvalid);
            setUIState("open");
            setIsLoading(false);
          }catch(e){
            setInvalidParams();
          }

        }
      }
    };
    if (token && token.length > 10) {
      handleToken();
    }
  }, [token, BASE_URL, env, region]);

  const { data: { state } = { state: null }, error: errorState, mutate: refreshState } = useSWR(
    !isInvalid && token && token.length > 10 && UIState === "open"
      ? [`${BASE_URL}/link/state`, token]
      : null,
    fetcher,
    {
      // every 15 seconds
      refreshInterval: finishedConnecting ? 0 : 15000,
      refreshWhenHidden: finishedConnecting ? false : true,
      revalidateOnFocus: finishedConnecting ? false : true,
      revalidateOnMount: finishedConnecting ? false : true,
      revalidateOnReconnect: finishedConnecting ? false : true,
    }
  );

  const closeIframe = () => {
    sendEventMessage(isMobile, VITAL_EVENTS_V1.CLOSE, {}, origin);
    setUIState("closed");
    if (!isMobile) {
      history.push("/");
      setIsLoading(true);
    }
  };

  const onSuccess = (state, sourceName, sourceSlug) => {
    sendEventMessage(
      isMobile,
      VITAL_EVENTS_V1.SUCCESS,
      {
        source: sourceName,
        state: state,
        source_slug: sourceSlug,
      },
      origin
    );
    closeIframe();
  };

  const onFailed = (state) => {
    sendEventMessage(isMobile, VITAL_EVENTS_V1.FAILED, { state }, origin);
    closeIframe();
  };

  return {
    isLoading: isLoading,
    isInvalid: isInvalid || errorState,
    providers,
    state,
    token,
    UIState,
    closeIframe,
    isMobile,
    onSuccess,
    onFailed,
    teamConfig,
    env,
    setFinishedConnecting,
    initialPage,
    region,
    origin,
    refreshState
  };
};
