import { VITAL_EVENTS_V1, VITAL_EVENTS_V2 } from "../models";

export const sendEventMessage = (
  isMobile: boolean,
  eventId: VITAL_EVENTS_V1,
  eventObject: Record<string, string | number | boolean | string[]>,
  origin: string
) => {
  try {
    if (!isMobile && window.parent) {
      window.parent.postMessage(
        JSON.stringify({
          id: eventId,
          ...eventObject,
        }),
        origin
      );
      // @ts-ignore
    } else if (window && window?.ReactNativeWebView) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(eventId);
    } else {
      console.warn("Could not deliver event messages");
    }
  } catch (e) {
    console.warn("Could not deliver event messages");
  }
};

export const sendEventMessageV2 = (
  isMobile: boolean,
  eventId: VITAL_EVENTS_V2,
  eventObject: Record<string, string | number | boolean | string[]>,
  origin: string
) => {
  const body = JSON.stringify({
    id: eventId,
    ...eventObject,
  });
  try {
    if (!isMobile && window.parent) {
      window.parent.postMessage(body, origin);
      // @ts-ignore
    } else if (window && window?.ReactNativeWebView) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(body);
    } else {
      console.warn("Could not deliver event messages");
    }
  } catch (e) {
    console.warn("Could not deliver event messages");
  }
};
