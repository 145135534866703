// @ts-nocheck
import { useState } from "react";
import { AuthorizingPage } from "./AuthorizingPage";
import { ConsentPage } from "./ConsentPage";
import { PasswordPage } from "./PasswordPage";
import { DevicePage } from "./DevicePage";
import { ProviderMFAPage } from "./ProviderMFAPage";

import { Device, VITAL_EVENTS_V2, WizardProps } from "../models";
import { ApiClient } from "../lib/api";
import { useEffect } from "react";
import StepWizard from "react-step-wizard";
import { InfoPage } from "./InfoPage";
import { sendEventMessageV2 } from "../lib/utils";

export const VitalLink = ({
  token,
  providers,
  state,
  closeIframe,
  isMobile,
  onSuccess,
  onFailed,
  teamConfig,
  env,
  setFinishedConnecting,
  initialPage,
  region,
  origin,
  refreshState,
}) => {
  const client = new ApiClient();
  const [device, setDevice] = useState<Device>(initialPage.provider);
  const [wizardProps, setWizardProps] = useState<WizardProps>({
    linkCompleted: null,
  });

  useEffect(() => {
    if (initialPage.id === "password") {
      setDevice(initialPage.provider);
    }
  }, [initialPage]);

  const getInitialStep = () => {
    if (initialPage.provider && initialPage.provider.auth_type === "email") {
      return 4;
    }
    if (initialPage.provider && initialPage.provider.auth_type === "password") {
      return 3;
    }
    return 0;
  };
  const updateWizardProps = (data: WizardProps) => setWizardProps(data);

  const onCloseWidget = () => {
    closeIframe();
    sendEventMessageV2(isMobile, VITAL_EVENTS_V2.EXIT, {});
  };
  return (
    <StepWizard
      className={"step-wizard-container"}
      transitions={{}}
      initialStep={getInitialStep()}
    >
      <ConsentPage
        id="step"
        className={"step"}
        stepName="consent"
        companyName={teamConfig?.name}
        companyLogo={teamConfig?.logo}
        onClose={onCloseWidget}
        isMobile={isMobile}
        teamConfig={teamConfig}
      />
      <DevicePage
        stepName="device"
        devices={providers}
        onDeviceSelect={setDevice}
        onStartAuth={(slug) => client.startAuth(token, slug, env, region)}
        onClose={onCloseWidget}
        isMobile={isMobile}
      />
      <PasswordPage
        stepName="password"
        device={device}
        state={state}
        onClose={onCloseWidget}
        isMobile={isMobile}
        token={token}
        env={env}
        region={region}
        showBackButton={getInitialStep() !== 3}
        setWizardProps={updateWizardProps}
      />
      <InfoPage
        stepName="email"
        device={device}
        state={state}
        onClose={onCloseWidget}
        isMobile={isMobile}
        token={token}
        env={env}
        showBackButton={getInitialStep() !== 4}
        region={region}
        setWizardProps={updateWizardProps}
      />
      <ProviderMFAPage
        stepName="provider_mfa"
        device={device}
        state={state}
        onClose={onCloseWidget}
        isMobile={isMobile}
        token={token}
        env={env}
        region={region}
        showBackButton={false}
        wizardProps={wizardProps}
        setWizardProps={updateWizardProps}
      />
      <AuthorizingPage
        stepName="state"
        device={device}
        state={state}
        onClose={onCloseWidget}
        isMobile={isMobile}
        onSuccess={onSuccess}
        onFailed={onFailed}
        onBack={() => true}
        wizardProps={wizardProps}
        setFinishedConnecting={setFinishedConnecting}
        companyName={teamConfig?.name}
        origin={origin}
        refreshState={refreshState}
      />
    </StepWizard>
  );
};
