import React, { useState } from "react";
import { Input, Box, Flex } from "theme-ui";
import { ListItem } from "./ListItem";
import { useCombobox } from "downshift";
import { ImSearch } from "react-icons/im";
import { Device } from "../models";

const menuStyles = {
  width: "100%",
  overflowY: "auto",
  height: "100%",
  padding: 0,
  listStyle: "none",
  position: "relative",
};

const comboboxStyles = {
  display: "flex",
  width: "100%",
  flexDirection: "column",
};

export const SearchBar: React.FunctionComponent<{
  onClick: (device: Device) => void;
  items: Device[];
  isMobile: boolean;
}> = ({ onClick, items, isMobile }) => {
  const [inputItems, setInputItems] = useState(items);
  const {
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    itemToString: (item) => (item ? item.name : ""),
    items: inputItems,
    defaultIsOpen: true,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        items.filter((item) =>
          item.name
            .toLowerCase()
            .startsWith(inputValue ? inputValue.toLowerCase() : "")
        )
      );
    },
  });

  return (
    <Flex
      sx={{
        width: "100%",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box style={comboboxStyles} my={3} px={4} {...getComboboxProps()}>
        <div className="input-wrapper">
          <ImSearch
            className="input-icon"
            color={"gray"}
            style={{ fontSize: 12 }}
          />
          <Input
            {...getInputProps()}
            placeholder="Search"
            sx={{
              border: "1px solid gray",
              fontSize: 2,
              py: "10px",
              width: "100%",
              paddingLeft: "28px",
            }}
          />
        </div>
      </Box>
      <Box sx={{ overflowY: "hidden", height: "100%" }}>
        <ul
          {...getMenuProps()}
          style={{
            ...menuStyles,
            paddingBottom: "50px",
            overflowY: "scroll",
          }}
        >
          {inputItems
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item, index) => (
              <ListItem
                key={index}
                getItemProps={getItemProps}
                item={item}
                index={index}
                highlightedIndex={highlightedIndex}
                onClick={onClick}
              />
            ))}
        </ul>
      </Box>
    </Flex>
  );
};
