export const CONFIG = {
  eu: {
    production: process.env.REACT_APP_EU_API_BASE_URL,
    sandbox: process.env.REACT_APP_EU_SANDBOX_API_BASE_URL,
    dev: process.env.REACT_APP_EU_DEV_API_BASE_URL,
    development: process.env.REACT_APP_EU_DEV_API_BASE_URL,
  },
  us: {
    production: process.env.REACT_APP_API_BASE_URL,
    sandbox: process.env.REACT_APP_API_SANDBOX_BASE_URL,
    dev: process.env.REACT_APP_API_DEV_BASE_URL,
    development: process.env.REACT_APP_API_DEV_BASE_URL,
  },
};

export const getBaseUrl = (
  env: string = "production",
  region: string = "us"
): string => {
  if (region == null || region === "null" || region === "undefined") {
    console.warn(
      `The 'region' parameter is null. The default 
      is set to the 'us'. If you want to use the EU 
      region, please set the 'region' parameter to 'eu'`
    );
    return CONFIG["us"][env];
  } else {
    return CONFIG[region][env];
  }
};
