import { Flex, Text, Button, Image, Input, Box } from "theme-ui";
import { Header } from "../Components";
import { Device, WizardProps } from "../models";
import * as Loader from "react-loader-spinner";
import { useState } from "react";
import { ApiClient, HTTPError } from "../lib/api";
import { StepWizardChildProps } from "react-step-wizard";
import { RiLockLine } from "react-icons/ri";
import { link_error_consumer_facing_copy } from "../lib/link_errors";

interface ProviderMFAPageProps extends StepWizardChildProps {
  device: Device;
  state: string;
  onClose: () => void;
  isMobile: boolean;
  token: string;
  env: string;
  region: string;
  showBackButton: boolean;
  wizardProps: WizardProps;
  setWizardProps: (data: WizardProps) => void;
}

const LogoHeader = ({ logo, name, description }) => (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      mb: "10px",
      mt: 20,
      px: 4,
    }}
  >
    <Image src={logo} width={60} sx={{ mr: 10 }} />
    <Flex sx={{ flexDirection: "column" }}>
      <Text sx={{ fontSize: 2, fontWeight: 600 }}>{name}</Text>
      <Text sx={{ fontSize: 0 }}>{description}</Text>
    </Flex>
  </Flex>
);

const MFACodeInput = ({
  providerName,
  method,
  hint,
  onChange,
  currentForm,
}: {
  providerName: string;
  method: string;
  hint: string;
  onChange: (currentFrom: ProviderMFAForm) => void;
  currentForm: ProviderMFAForm;
}) => {
  return (
    <Box px={2} py={4} sx={{ width: "100%" }}>
      <Text sx={{ fontSize: 2 }}>
        {assemble_mfa_copy(providerName, method, hint)}
      </Text>
      <div style={{ position: "relative" }}>
        <Input
          placeholder={"Verification code"}
          mb={3}
          mt={4}
          name="mfa_code"
          type="text"
          onChange={(e) =>
            onChange({ ...currentForm, mfa_code: e.target.value })
          }
        />
        <RiLockLine
          size={20}
          style={{ position: "absolute", right: 15, top: 15 }}
        />
      </div>
    </Box>
  );
};

interface ProviderMFAForm {
  mfa_code: string;
}

export const ProviderMFAPage: React.FunctionComponent<
  Partial<ProviderMFAPageProps>
> = ({
  device,
  state,
  token,
  onClose,
  env,
  showBackButton,
  region,
  wizardProps,
  setWizardProps,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [currentForm, setForm] = useState<ProviderMFAForm>({
    mfa_code: "",
  });
  const [error, setError] = useState<{ error_type: string, status?: number } | null>(null);
  const client = new ApiClient();

  const handleSubmit = async (currentForm: ProviderMFAForm) => {
    console.log("currentForm", currentForm)
    if (!(currentForm.mfa_code)) return null;
    setLoading(true);
    setError(null);
    try {
      const resp = await client.completeProviderMFA(
        currentForm.mfa_code,
        device.slug,
        token,
        env,
        region
      );

      switch (resp.state) {
        case "success":
          setWizardProps({ linkCompleted: { redirectUrl: resp.redirect_url } });
          props.goToNamedStep("state");
          break;

        case "error":
          setLoading(false);
          setError({ error_type: resp.error_type });
          break;

        case "pending_provider_mfa":
          setLoading(false);
          setError({ error_type: "unknown" });
          console.log("Unexpected state `pending_provider_mfa` from completeProviderMFA");
          break;
      }

    } catch (e) {
      setLoading(false);
      setError({ error_type: "unknown", status: (e instanceof HTTPError ? e.status : null) });
    }
  };

  if (!device || !wizardProps.provider_mfa) {
    return null;
  }

  const mfa = wizardProps.provider_mfa;

  return (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: "100%",
        width: "100%",
      }}
      pt={4}
    >
      <Flex
        sx={{
          alignItems: "flex-start",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Header
          showBack={!loading && showBackButton}
          onBack={() => props.previousStep()}
          onClose={onClose}
          hideExitButton={false}
        />
        <LogoHeader
          name={device.name}
          logo={device.logo}
          description={device.description}
        />
        <Box px={4} style={{ width: "100%" }}>
          <MFACodeInput providerName={device.name} method={mfa.method} hint={mfa.hint} onChange={setForm} currentForm={currentForm} />
          {error && <ErrorText error_type={error.error_type} />}
        </Box>
      </Flex>

      <Flex px={4} sx={{ width: "100%" }} pb={4}>
        <Button
          onClick={() => handleSubmit(currentForm)}
          sx={{ width: "100%", mt: 10 }}
        >
          <Box sx={{ height: 25 }}>
            {loading ? (
              <Loader.Oval color={"white"} width={25} height={25} wrapperStyle={{"justifyContent": "center"}}/>
            ) : (
              "Submit"
            )}
          </Box>
        </Button>
      </Flex>
    </Flex>
  );
};

const ErrorText: React.FC<{ error_type: string, status?: number }> = (error) => {
  return (
    <Text sx={{ color: "red", fontSize: 1, textAlign: "center" }}>
      {link_error_consumer_facing_copy(error.error_type, error.status)}
    </Text>
  );
};

const assemble_mfa_copy = (provider: string, method: string, hint: string) => {
  switch (method) {
    case "sms":
      return `${provider} has sent an SMS verification code to ${hint}.`;
    case "email":
      return `${provider} has sent an email verification code to ${hint}.`;
    default:
      return `${provider} has sent a verification code to your preferred communication channel.`;
  }
}
