import { Button, Flex, Box, Text, Image } from "theme-ui";
import { ExitButton } from "../Components/ExitButton";
import Logo from "../images/logo.svg";
import Avatar from "react-avatar";

export const ConsentPage = ({
  onClose,
  isMobile,
  companyName,
  teamConfig,
  ...props
}) => {
  return (
    <Flex
      id="conentBlock"
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
      p={4}
    >
      {!isMobile && (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          <ExitButton onClose={onClose} />
        </Box>
      )}
      <Flex
        sx={{ flexDirection: "column", alignItems: "center", width: "100%" }}
      >
        {isMobile && (
          <Box
            sx={{
              textAlign: "right",
              width: "100%",
              paddingBottom: ["0%", "5%", "5%"],
            }}
          >
            <ExitButton onClose={onClose} />
          </Box>
        )}
        <Box sx={{ pb: 40 }}>
          <ul style={{ listStyleType: "none", margin: 0, padding: 0, display: "flex"}}>
            <li className="avatar-style">
              {teamConfig?.logo ? (
                <Image src={teamConfig?.logo} style={{borderRadius: "100px"}}/>
              ) : (
                <Box sx={{ height: "60px", width: "60px", overflow: "hidden" }}>
                  { /* @ts-expect-error : ConfigProvider has no children property in interface declaration */}
                  <Avatar size={"60px"} round name={teamConfig?.name} />
                </Box>
              )}
            </li>
            <li className="avatar-style">
              <Image src={Logo} />
            </li>
          </ul>
        </Box>
        <Box sx={{ textAlign: "center", fontWeight: 300, fontSize: 20 }}>
          <span style={{ fontWeight: 600, fontSize: 20 }}>{companyName}</span>{" "}
          uses <span style={{ fontWeight: 600, fontSize: 20 }}>Vital</span> to
          link your wearables data.
        </Box>

        <Box marginTop={30}>
          <Box sx={{ textAlign: "left", mb: 30 }}>
            <Text sx={{ fontSize: 2, fontWeight: 500, mb: 10 }}>Secure</Text>
            <Text sx={{ fontSize: 2, fontWeight: 300 }}>
              Data is encrypted and stored in a compliant manner
            </Text>
          </Box>

          <Box sx={{ textAlign: "left" }}>
            <Text sx={{ fontSize: 2, fontWeight: 500, mb: 10 }}>Private</Text>
            <Text sx={{ fontSize: 2, fontWeight: 300 }}>
              Data is never sold to a third party. You’re in control of who has
              access to your records.
            </Text>
          </Box>
        </Box>
      </Flex>

      <Box sx={{ width: "100%" }}>
        <Text
          sx={{ fontSize: 0, color: "secondary", my: 3, textAlign: "center" }}
        >
          By selecting "Continue" you agree to the Vital End User{" "}
          <a
            href={"https://tryvital.io/privacy"}
            style={{ textDecoration: "underline" }}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </Text>
        <Button sx={{ width: "100%" }} onClick={() => props.nextStep()}>
          Continue
        </Button>
      </Box>
    </Flex>
  );
};
