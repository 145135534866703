import { Button } from "theme-ui";
import { IoClose } from "react-icons/io5";

export const ExitButton = ({onClose}) => {
  return (
    <Button p={0} sx={{ bg: "white", color: "black" }} onClick={onClose}>
      <IoClose size={25}/>
    </Button>
  );
};
