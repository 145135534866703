import { Text, Box, Image, Flex, Button } from "theme-ui";

const successText = (teamName: string) => {
  if (teamName) {
    return `Your device has been succesfully connected to ${teamName}. You can now head back to the app.`;
  }
  return `Your device has been succesfully connected. You can now head back to the app.`;
};

const SuccessImageContent = () => (
  <Image
    src={"/images/success.svg"}
    width={140}
    sx={{ textAlign: "center", mt: 40 }}
  />
);

const SuccessContent = ({ teamName }) => (
  <Box sx={{ textAlign: "center", mt: 40 }}>
    <Text
      sx={{
        fontSize: "1.3rem",
        fontWeight: 600,
        mb: "15px",
        color: "#111111",
        lineHeight: 1.5,
      }}
    >
      Success
    </Text>
    <Text
      sx={{
        fontSize: 2,
        fontWeight: 300,
        textAlign: "center",
        color: "#383838",
        lineHeight: 1.5,
      }}
    >
      {successText(teamName)}
    </Text>
  </Box>
);

export const SuccessClose = ({ teamName }) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        px: 4,
        py: 4,
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ textAlign: "center", px: 1, height: "100%" }}>
        <Box sx={{ width: 140, height: 150 }}>
          <SuccessImageContent />
        </Box>
        <SuccessContent teamName={teamName} />
      </Box>
    </Flex>
  );
};

export const SuccessCloseWithButton = ({
  onContinue,
  teamName,
  isMobile,
}: {
  onContinue: () => void;
  teamName?: string;
  isMobile?: boolean;
}) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        px: 4,
        py: 4,
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ textAlign: "center", px: 1 }}>
        <SuccessImageContent />
        <SuccessContent teamName={teamName} />
      </Box>

      <Button
        sx={{ width: "100%" }}
        onClick={onContinue}
        mb={isMobile ? 50 : 0}
      >
        Continue
      </Button>
    </Flex>
  );
};

interface SuccessProps {
  onContinue: () => void;
  isMobile?: boolean;
  teamName?: string;
}
export const Success: React.FunctionComponent<SuccessProps> = ({
  onContinue,
  isMobile,
  teamName,
}) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        px: 4,
        py: 4,
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ textAlign: "center", px: 1 }}>
        <Flex
          sx={{
            height: 150,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <SuccessImageContent />
        </Flex>
        <SuccessContent teamName={teamName} />
      </Box>

      <Button
        sx={{ width: "100%" }}
        onClick={onContinue}
        mb={isMobile ? 50 : 0}
      >
        Continue
      </Button>
    </Flex>
  );
};
