export const Theme = {
  breakpoints: ["48em", "64em", "85.375em", "120em", "160em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: `Inter, system-ui, sans-serif`,
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 800,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors: {
    text: "black",
    background: "transparent",
    primary: "black",
    // highlight: '#FFF',
    // accent: '#FFF',
    secondary: "gray",
    muted: "#f6f6f6",
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  variants: {},
  text: {
    heading: {
      fontFamily: "heading",
      color: "rgba(255, 255, 255, .95)",
    },
    white: {
      color: "rgba(255, 255, 255, .87)",
    },
  },
  buttons: {
    primary: {
      color: "white",
      bg: "primary",
      fontFamily: "inherit",
      outline: "none",
      ":hover": { transition: "0.3s", opacity: 0.8 },
      cursor: "pointer",
      padding: "15px"
    },
  },
  input: {
    py: "16px",
    px: "14px",
    border: "1px Solid rgba(144,144,144,0.8)"
  },
  select: {
    py: "16px",
    px: "14px",
    border: "1px Solid rgba(144,144,144,0.8)"
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
    },
    a: {
      color: "text",
      textDecoration: "none",
      ":hover": { transition: "0.3s", opacity: 0.8, color: "primary" },
      cursor: "pointer",
    },
  },
};
