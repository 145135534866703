import { Flex, Text, Box } from "theme-ui";
import { Header, Success, Error } from "../Components";
import { Device, VITAL_EVENTS_V2, WizardProps } from "../models";
import * as Loader from "react-loader-spinner";
import { StepWizardChildProps } from "react-step-wizard";
import { useState } from "react";
import { useEffect } from "react";
import { sendEventMessageV2 } from "../lib/utils";

interface AuthorizingPageProps extends StepWizardChildProps {
  device: Device;
  state: string;
  onClose: () => void;
  onSuccess?: (state: string, sourceName: string, sourceSlug: string) => void;
  onFailed: (state: string) => void;
  isMobile: boolean;
  onBack: () => void;
  setFinishedConnecting: (hasFinished: boolean) => void;
  wizardProps: WizardProps;
  companyName: string;
  origin: string;
  refreshState: () => Promise<void>;
}

const Connecting: React.FunctionComponent<{ device: string }> = ({
  device,
}) => (
  <Box sx={{ textAlign: "center", px: 1, mt: 40 }}>
    <Box sx={{ textAlign: "center", mt: 40 }}>
      <Loader.Grid color="#D3D3D3" height={50} width={50} wrapperStyle={{"justifyContent": "center"}}/>
    </Box>
    <Box sx={{ textAlign: "center", mt: 40 }}>
      <Text
        sx={{
          fontSize: "1.3rem",
          fontWeight: 600,
          mb: "15px",
          color: "#111111",
          lineHeight: 1.5,
        }}
      >
        Connecting {device}
      </Text>
      <Text
        sx={{
          fontSize: 2,
          fontWeight: 300,
          textAlign: "center",
          color: "#383838",
          lineHeight: 1.5,
        }}
      >
        We're working hard to fetch your data.
      </Text>
    </Box>
  </Box>
);

export const AuthorizingPage: React.FunctionComponent<
  Partial<AuthorizingPageProps>
> = ({
  device,
  state,
  onClose,
  isMobile,
  onSuccess,
  onFailed,
  onBack,
  setFinishedConnecting,
  wizardProps,
  companyName,
  origin,
  isActive,
  refreshState,
}) => {
  const [isLoadingFinalState, setLoadingFinalState] = useState(true);

  const [hasTimedOut, setHasTimedOut] = useState(false);
  const redirect = () => {
    const linkCompleted = wizardProps.linkCompleted;

    if (linkCompleted) {
      window?.open(linkCompleted.redirectUrl, "_self");
    }
  };

  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (state !== "success" && state !== "failed") {
        setHasTimedOut(true);
      }
    }, 60 * 2 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [state]);

  useEffect(() => {
    // Don't trigger the final state loading if the page is not active.
    if (!isActive) {
      return;
    }

    // Stop polling and trigger the final state loading, only when:
    //
    // 1. state is neither null nor `oauth_started`.
    //
    // 2. wizardProps.linkCompleted is non-null
    //    after email/password/MFA step completion
    //
    // This ensures `/link/state` pooling would continue to run when state is either
    // null or oauth_started.
    if ((!state || state === "oauth_started") && !wizardProps.linkCompleted) {
      return;
    }

    setFinishedConnecting(true);

    refreshState().then(() => {
      setLoadingFinalState(false);
    });

  }, [isActive, state, wizardProps.linkCompleted, refreshState, setFinishedConnecting, setLoadingFinalState]);

  useEffect(() => {
    if (!isActive || isLoadingFinalState) {
      return;
    }

    if (state === "success") {
      sendEventMessageV2(
        isMobile,
        VITAL_EVENTS_V2.CONNECT_SUCCESS,
        {
          name: device?.name,
          provider: device?.slug,
          state: "connect_success",
        },
        origin
      );

    } else if (state === "failed") {
      sendEventMessageV2(
        isMobile,
        VITAL_EVENTS_V2.CONNECT_ERROR,
        {
          name: device?.name,
          provider: device?.slug,
          state: "connect_error",
        },
        origin
      );
    }

    // eslint-disable-next-line
  }, [state, isActive, isLoadingFinalState]);

  if (!device && state !== "failed") {
    return null;
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
      pt={4}
    >
      {isLoadingFinalState && !hasTimedOut && (
        <>
          <Header
            showBack={false}
            onBack={onBack}
            onClose={onClose}
            hideExitButton={false}
          />
          <Connecting device={device.name} />
        </>
      )}
      {state === "success" && !hasTimedOut && (
        <>
          <Header
            showBack={false}
            onBack={() => {}}
            onClose={() => {}}
            hideExitButton={true}
          />
          <Success
            onContinue={() => {
              onSuccess("success", device.name, device.slug);
              sendEventMessageV2(
                isMobile,
                VITAL_EVENTS_V2.HANDOFF_SUCCESS,
                {
                  name: device.name,
                  provider: device.slug,
                  state: "success",
                },
                origin
              );
              redirect();
            }}
            isMobile={isMobile}
            teamName={companyName}
          />
        </>
      )}
      {(state === "failed" || hasTimedOut) && (
        <>
          <Header
            showBack={false}
            onBack={onBack}
            onClose={onClose}
            hideExitButton={false}
          />
          <Error
            onContinue={() => {
              onFailed(state);
              sendEventMessageV2(
                isMobile,
                VITAL_EVENTS_V2.HANDOFF_FAILED,
                {
                  name: device?.name,
                  provider: device?.slug,
                  state: "failed",
                },
                origin
              );
              redirect();
            }}
            isMobile={isMobile}
          />
        </>
      )}
    </Flex>
  );
};
