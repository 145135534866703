import { VitalLink } from "./VitalLink";
import { Layout } from "../Components";
import { GetLinkParameters } from "../Loaders/linkParameters";

export const VitalLinkContainer = () => {
  const props = GetLinkParameters();
  const {
    isLoading,
    isInvalid,
    providers,
    state,
    token,
    UIState,
    closeIframe,
    isMobile,
    onFailed,
    onSuccess,
    teamConfig,
    env,
    setFinishedConnecting,
    initialPage,
    region,
    origin,
    refreshState,
  } = props;
  return (
    <Layout
      isLoading={isLoading}
      title={"Vital Link"}
      error={isInvalid}
      UIState={UIState}
      closeIframe={closeIframe}
      isMobile={isMobile}
      onFailed={onFailed}
      onSuccess={onSuccess}
    >
      <VitalLink
        teamConfig={teamConfig}
        token={token}
        providers={providers}
        state={state}
        closeIframe={closeIframe}
        isMobile={isMobile}
        onFailed={onFailed}
        onSuccess={onSuccess}
        env={env}
        setFinishedConnecting={setFinishedConnecting}
        initialPage={initialPage}
        region={region}
        origin={origin}
        refreshState={refreshState}
      />
    </Layout>
  );
};
