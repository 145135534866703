import { parse } from "query-string";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { Layout } from "../Components";
import { ErrorCloseWithButton } from "../Components/Error";
import { SuccessCloseWithButton } from "../Components/Success";
import { sendEventMessage, sendEventMessageV2 } from "../lib/utils";
import { VITAL_EVENTS_V1, VITAL_EVENTS_V2 } from "../models";

export const CompletePage = () => {
  const [isLoaded, setLoaded] = useState(false);

  const history = useHistory();
  const {
    state,
    isMobile: mobileQuery,
    provider,
    origin,
  } = parse(history.location.search);
  const isMobile = mobileQuery === "true" || mobileQuery === "True";

  const originString = origin as string;
  const closeMessage = () => {
    sendEventMessage(
      isMobile,
      VITAL_EVENTS_V1.CLOSE,
      {
        provider,
        state,
      },
      originString
    );
    setLoaded(true);
  };

  useEffect(() => {
    if (!isMobile) {
      window.close();
    }
    sendEventMessage(
      isMobile,
      state === "success" ? VITAL_EVENTS_V1.SUCCESS : VITAL_EVENTS_V1.FAILED,
      {
        provider,
        state,
      },
      originString
    );

    sendEventMessageV2(
      isMobile,
      state === "success"
        ? VITAL_EVENTS_V2.CONNECT_SUCCESS
        : VITAL_EVENTS_V2.CONNECT_ERROR,
      {
        provider,
        state,
      },
      originString
    );
    closeMessage();
    // eslint-disable-next-line
  }, [isMobile, provider, state]);

  const onSuccess = () => {
    if (isMobile) {
      sendEventMessage(
        true,
        VITAL_EVENTS_V1.SUCCESS,
        { provider, state },
        originString
      );
      sendEventMessage(true, VITAL_EVENTS_V1.CLOSE, {}, originString);
      sendEventMessageV2(
        true,
        VITAL_EVENTS_V2.HANDOFF_SUCCESS,
        {
          provider,
          state,
        },
        originString
      );
    } else {
      window.close();
    }
  };
  const onError = () => {
    if (isMobile) {
      sendEventMessage(true, VITAL_EVENTS_V1.FAILED, {}, originString);
      sendEventMessage(true, VITAL_EVENTS_V1.CLOSE, {}, originString);
      sendEventMessageV2(
        true,
        VITAL_EVENTS_V2.HANDOFF_FAILED,
        {
          provider,
          state,
        },
        originString
      );
    } else {
      window.close();
    }
  };

  return (
    isLoaded && (
      <Layout
        title={"Vital | Wearables API"}
        isLoading={false}
        closeIframe={() => {
          if (!isMobile) {
            window.close();
          } else {
            sendEventMessageV2(
              isMobile,
              VITAL_EVENTS_V2.EXIT,
              {},
              originString
            );
          }
        }}
        UIState={"open"}
        onSuccess={() => true}
        onFailed={() => true}
        isMobile={isMobile}
      >
        {state === "success" ? (
          <SuccessCloseWithButton onContinue={onSuccess} isMobile={isMobile} />
        ) : (
          <ErrorCloseWithButton onContinue={onError} isMobile={isMobile} />
        )}
      </Layout>
    )
  );
};
