export type LinkErrorType = (
  "duplicate_connection"
  | "unsupported_region"
  | "provider_credential_error"
  | "provider_api_error"
  | "invalid_token"
  | "token_consumed"
)

const LINK_ERROR_CONSUMER_FACING_COPIES: Record<LinkErrorType, string> = {
  "duplicate_connection": "The supplied credential has already been connected to another user.",
  "unsupported_region": "The selected region is not supported by the provider.",
  "provider_credential_error": "Invalid username or password.",
  "provider_api_error": "Error occurred when connecting to the provider.",
  "invalid_token": "The Link session has expired. Please start a new one.",
  "token_consumed": "The Link session has expired. Please start a new one.",
};

export const link_error_consumer_facing_copy = (error_type: string, status: number = undefined) => {
  const copy = LINK_ERROR_CONSUMER_FACING_COPIES[error_type];
  if (copy) {
    return copy;
  }

  return "An unknown error occurred." + (status ? ` (${status})` : '');
};
