import { ThemeProvider, Flex } from "theme-ui";
import { Theme } from "../theme";
import * as Loader from "react-loader-spinner";
import { AuthorizingPage } from "../pages/AuthorizingPage";
import { useEffect, useState } from "react";
import Div100vh from "react-div-100vh";

const CardContainer = ({ children, isLoading, isMobile }) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isUsingMobileBrowser = width <= 768;

  if (isMobile || isUsingMobileBrowser) {
    return (
      <Div100vh style={{height: "100%"}}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            background: "white",
            height: "100%"
          }}
        >
          {children}
        </div>
      </Div100vh>
    );
  }

  return (
    <div
      id="vital-overlay"
      className="vital-overlay"
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(144,144,144,0.9)",
      }}
    >
      <div
        style={{
          width: 420,
          height: "650px",
          margin: "auto",
          background: isLoading ? "transparent " : "white",
          borderRadius: "8px",
          boxShadow: isLoading ? null : "0 8px 16px 0 rgb(0 0 0 / 20%)",
          fontFamily: "Inter",
          fontSize: 20,
        }}
      >
        {children}
      </div>
    </div>
  );
};

const LoadingContainer = ({
  isLoading,
  children,
  error,
  closeIframe,
  isMobile,
  onSuccess,
  onFailed,
}) => {
  return isLoading ? (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Loader.Rings color="#D3D3D3" height={60} width={60} wrapperStyle={{"justifyContent": "center"}}/>
    </Flex>
  ) : error ? (
    <AuthorizingPage
      device={null}
      state={"failed"}
      onClose={closeIframe}
      isMobile={isMobile}
      onSuccess={onSuccess}
      onFailed={onFailed}
      onBack={() => true}
      setFinishedConnecting={() => true}
      origin={""}
    />
  ) : (
    children
  );
};

export const Layout: React.FunctionComponent<{
  title: string;
  isLoading: boolean;
  error?: boolean;
  UIState?: "open" | "closed";
  isMobile?: boolean;
  closeIframe: () => void;
  onSuccess: (state: string, sourceName: string, sourceSlug: string) => void;
  onFailed: (state: string) => void;
}> = ({
  children,
  title,
  isLoading,
  error,
  UIState,
  closeIframe,
  isMobile,
  onSuccess,
  onFailed,
}) => {
  return (
    <ThemeProvider theme={{ ...Theme }}>
      <CardContainer isMobile={isMobile} isLoading={isLoading}>
        <LoadingContainer
          isLoading={isLoading}
          error={error}
          closeIframe={closeIframe}
          isMobile={isMobile}
          onSuccess={onSuccess}
          onFailed={onFailed}
        >
          {UIState === "open" && children}
        </LoadingContainer>
      </CardContainer>
    </ThemeProvider>
  );
};
